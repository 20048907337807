@import '../../../../scss/theme-bootstrap';

// loyalty signup
.loyalty-signup-panel {
  padding: 20px 20px 50px;
  text-align: $ldirection;
  color: $color-navy;
  overflow: hidden;
  margin: 0 auto;
  a {
    text-decoration: underline;
  }
  &__valid-used {
    text-align: $ldirection;
    @media #{$medium-up} {
      text-align: center;
    }
    .button {
      &:last-child {
        margin: 15px 0 0;
      }
    }
  }
  &__valid-used,
  &__valid-unused {
    .button {
      width: 100%;
      @media #{$medium-up} {
        width: 270px;
      }
    }
  }
  &__error,
  &__signup,
  &__valid-used,
  &__valid-unused,
  &__invalid-used,
  &__invalid-unused {
    .gnav-signup-overlay & {
      min-width: 420px;
    }
  }
  input[type='hidden'] ~ .loyalty-signup-panel__checkbox-label::before,
  input[type='hidden'] ~ .loyalty-signup-panel__checkbox-label::after {
    @include swap_direction(border, medium none);
    background: $color-black none repeat scroll 0 0;
    padding-#{$ldirection}: 0;
  }
  &__buttons,
  &__button {
    margin-top: 35px;
    .button {
      text-decoration: none;
      border: 1px solid $color-navy;
      color: $color-white;
      background: $color-navy;
    }
    .loyalty-signup-panel__valid-unused & {
      margin-top: 20px;
    }
    .loyalty-signup-panel__valid-used & {
      text-align: center;
      margin-top: 20px;
    }
  }
  &__checkbox-container {
    width: auto;
    margin-top: 15px;
  }
  &__checkbox-label {
    text-transform: none;
    font-size: 12px;
    letter-spacing: 0.05em;
    input ~ & {
      a {
        color: $color-navy;
      }
    }
  }
  &__checkbox-link {
    color: $color-navy;
  }
  &__choose {
    margin: 15px 0;
    font-size: 15px;
    letter-spacing: 0.05em;
    line-height: get-line-height(15px, 20px);
    text-transform: initial;
    width: 90%;
  }
  &__firstname {
    input[type='text'] {
      @include swap_direction(margin, 0 0 20px 0);
      width: 100%;
      &.filled-in {
        border: 1px solid $color-medium-gray;
        color: $color-navy;
      }
    }
  }
  &__header {
    font-size: 40px;
    font-style: italic;
    font-family: $font-bauerbodoni-roman;
    letter-spacing: -0.01em;
    line-height: 1;
  }
  &__input {
    display: block;
    overflow: hidden;
    .form-text,
    .form-password,
    input[type='email'],
    input[type='password'],
    input[type='text'] {
      @include box-shadow(none);
      @include input-placeholder {
        color: $color-medium-gray;
      }
      width: 100%;
      background-color: $color-white;
      border: 1px solid $color-medium-gray;
      color: $color-navy;
      @media #{$medium-up} {
        height: 40px;
        background-color: transparent;
        float: $ldirection;
        margin-#{$rdirection}: 9px;
      }
      .loyalty-signup-panel__invalid-used &,
      .loyalty-signup-panel__invalid-unused &,
      &:not(:only-child) {
        @media #{$medium-up} {
          width: 270px;
        }
      }
    }
    .form-submit {
      margin-top: 15px;
      line-height: get-line-height(15px, 42px);
      height: 42px;
      border: 1px solid $color-navy;
      color: $color-white;
      background: $color-navy;
      width: auto;
      @media #{$medium-up} {
        float: $ldirection;
        margin-top: 0;
        padding-#{$ldirection}: 17px;
        padding-#{$rdirection}: 17px;
        line-height: normal;
        height: 40px;
      }
    }
    .form-text {
      margin-bottom: 10px;
    }
  }
  &__link {
    font-size: 12px;
    letter-spacing: 0.15em;
    font-weight: bold;
    &-container {
      margin-top: 24px;
      padding-top: 10px;
      clear: #{$ldirection};
      .loyalty-signup-panel__invalid-unused &,
      .loyalty-signup-panel__invalid-used & {
        @media #{$medium-up} {
          margin-top: 20px;
          height: 38px;
        }
      }
    }
  }
  &__margin {
    @media #{$medium-up} {
      margin-#{$ldirection}: 8px;
    }
  }
  &__offer-note {
    font-size: 14px;
    margin-top: 10px;
    letter-spacing: 0.05em;
    @media #{$medium-up} {
      font-size: 12px;
    }
  }
  &__password {
    position: relative;
    margin-bottom: 60px;
    .form-password {
      width: 290px;
      margin-#{$rdirection}: 10px;
    }
    .password-field {
      &__info {
        background: none;
        display: flex;
        width: 100%;
        z-index: 99;
        margin-top: 5px;
        @media #{$medium-up} {
          position: absolute;
          top: 30px;
        }
      }
      &__rules {
        column-count: 2;
        padding: 10px;
        font-size: 12px;
        list-style: none;
        li {
          padding: 3px 0;
          display: flex;
          align-items: center;
          color: $color-black;
          transition: 0.2s;
          white-space: nowrap;
          color: $color-dark-gray;
          &::before {
            padding-#{$rdirection}: 6px;
            content: $cross;
            display: inline-block;
            color: $color-red;
            font-size: 1em;
            line-height: 0;
            transition: 0.2s;
          }
          &.pass::before {
            content: $tick;
            color: $color-green;
          }
        }
      }
    }
  }
  &__phone-input {
    width: 100%;
    margin-top: 15px;
  }
  &__promo-header {
    font-size: 15px;
    font-family: $akzidenz;
    letter-spacing: 0.05em;
    margin-top: 0;
    line-height: 0.83;
    @media #{$medium-up} {
      margin-top: 22px;
    }
  }
  &__promo-text {
    font-size: 14px;
    letter-spacing: 0.15em;
    line-height: get-line-height(14px, 18px);
    @media #{$medium-up} {
      font-size: 15px;
      line-height: get-line-height(15px, 24px);
    }
  }
  &__promo {
    letter-spacing: 0.05em;
    margin: 15px 0;
    text-transform: initial;
  }
  &__status {
    font-size: 12px;
    letter-spacing: 0.15em;
    line-height: get-line-height(12px, 26px);
    margin-bottom: 0;
    padding-top: 5px;
  }
  &__promo,
  &__status {
    a {
      display: inline-block;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.15em;
      @media #{$medium-up} {
        margin-#{$ldirection}: 10px;
      }
    }
  }
  &__sub-header {
    font-size: 12px;
    font-family: $akzidenz;
    letter-spacing: 0.15em;
    margin-top: 15px;
    line-height: 1.2;
    .loyalty-signup-panel__valid-used & {
      font-weight: bold;
    }
  }
  &__submit-container {
    @include clearfix;
    margin: 10px 0;
    .form-text {
      @media #{$medium-up} {
        height: 40px;
      }
    }
    .form-submit {
      @media #{$medium-up} {
        height: 40px;
        width: 102px;
      }
    }
  }
  &__terms {
    clear: both;
    font-size: 14px;
    margin-top: 10px;
    letter-spacing: 0.05em;
    @media #{$medium-up} {
      font-size: 12px;
    }
    a {
      color: $color-navy;
    }
  }
  &__link,
  &__checkbox-link,
  &__terms,
  &__privacy {
    color: $color-navy;
  }
}
